<template>
  <div class="card">
    <div class="card-body">
      <div class="card-header card-header-divider">
        <div class="card-title">{{ route.params.id ? 'Редактирование' : 'Создание'}} подписки</div>
      </div>
      <div class="card-body" v-if='isLoaded'>
        <form
            action="#"
            class="subscription_form"
            @submit.prevent="saveSubscription"
            novalidate
        >
          <div class="form-group">
            <custom-input
              v-model="subscriptionForm.title"
              name="title"
              label="Название"
            />

            <custom-input
                v-model="subscriptionForm.price"
                type="number"
                name="price"
                :min="0"
                label="Цена"
            />

            <custom-input
                v-model="subscriptionForm.duration_months"
                name="duration_months"
                type="number"
                label="Срок действия (в месяцах)"
            />
            <div class="available_checkbox">
              <custom-checkbox
                  v-model="subscriptionForm.available"
                  :checked="subscriptionForm.available"
                  name="available"
                  label="Доступна к покупке"
              />
            </div>
          </div>

          <div class="products_header">
            <p class="card-title">
              Состав
            </p>
          </div>

          <div v-for="(entity, index) in products" :key="index">
            <custom-select-2
                label="Тип сущности"
                name="entity"
                v-model="entity.type"
                :options="types"
            />

            <custom-select-2
                v-if="entity.type && entity.type === 'test'"
                label="Тип теста"
                name="entity"
                v-model="entity.id"
                :options="testOptions"
            />

            <custom-select-2
                v-if="entity.type && entity.type === 'course'"
                label="Тип теста"
                name="entity"
                v-model="entity.id"
                :options="coursesOptions"
            />

            <custom-input
                v-if="entity.type"
                v-model="entity.count"
                type="number"
                name="count"
                :min="0"
                label="Количество"
            />
            <div class="remove_button">
              <button v-if="index !== 0" @click="removeField(index)" type='button' class="btn btn-xs btn-dark">
                Убрать
              </button>
            </div>
          </div>
          <div class="append_button">
            <button @click="addField" type='button' class="btn btn-xs btn-accent">
              Добавить новый продукт
            </button>
          </div>
          <div class="control_button">
            <button type="submit" class="btn btn-accent">
              Сохранить
            </button>
          </div>
        </form>
      </div>
      <div v-else class="table-preloader">
        <preloader/>
      </div>
    </div>

  </div>
</template>

<script>
import Preloader from "@/components/Technical/Preloader.vue";
import {onBeforeMount, ref} from "@vue/runtime-core";
import {computed, reactive} from "@vue/reactivity";
import CustomSelect2 from "@/components/Forms/Fields/CustomSelect2.vue";
import CustomInput from "@/components/Forms/Fields/CustomInput.vue";
import {useRoute, useRouter} from "vue-router";
import {useStore} from "vuex";
import useCheckRights from "@/mixins/useCheckRights";
import router from "../../router";
import CustomCheckbox from "@/components/Forms/Fields/CustomCheckbox.vue";
import {notify} from "@kyvg/vue3-notification";
import standartError from "@/mixins/standartError";
import useErrors from "@/mixins/useErrors";

export default {
  name: "SubscriptionForm.vue",
  methods: {
    router() {
      return router
    }
  },
  components: {CustomCheckbox, CustomInput, CustomSelect2, Preloader},
  setup() {
    const {setErrors, clearCustomErrors} = useErrors();
    const isLoaded = ref(true);
    const { getRight } = useCheckRights();
    const route = useRoute();
    const store = useStore();
    const subscription = computed(() => store.state.subscription.subscription)
    const subscriptionForm = reactive({
      title: '',
      duration_months: 0,
      price: 0,
      available: true,
    });

    let products = reactive( [
      {
        id: null,
        title: '',
        type: '',
        count: null,
        source: '',
      }
    ]);

    const types = [
      { id: 'course', text: 'Курс' },
      { id: 'test', text: 'Тест' },
      { id: 'reference_profile', text: 'Эталонный профиль' },
      { id: 'trajectory', text: 'Траектории'}
    ];

    const entitiesOptions = computed(() => store.state.relations.entitiesList);
    const coursesOptions = computed(() => entitiesOptions.value.courses.map((course) => {
          return {
            id: course.id,
            text: course.title,
            source: course.source ?? null,
          }
        }
    ));
    const testOptions = computed(() => {
          let options = [];
          for (const [key, value] of Object.entries(entitiesOptions.value.tests)) {
            options.push(
                {
                  id: key,
                  text: value,
                }
            )
          }
          return options;
        }
    );

    const removeField = (index) => {
      products.splice(index, 1);
    }

    const addField = () => {
      products.push({
        id: null,
        title: '',
        type: '',
        count: null,
        source: '',
      });
    }

    const saveSubscription = () => {

    if (!subscriptionForm.title || !subscriptionForm.duration_months) {
      notify({
        title: "Необходимо заполнить все поля",
        type: "error"
      });
      return;
    }

      let collectedProducts = products.filter((p) => p.type.length && p.count);

      if (!collectedProducts.length) {
        notify({
          title: "Необходимо добавить хотя бы один продукт",
          type: "error"
        });
        return;
      }

      const subscriptionProducts = [];

      collectedProducts.forEach((product) => {
        let productObject = {
          type: product.type,
          count: product.count,
        }

        if (product.type === 'course') {
          if (!product.id) {
            notify({
              title: "Необходимо выбрать курс",
              type: "error"
            });
            return;
          }

          productObject.name = coursesOptions.value.find((course) => course.id === parseInt(product.id)).text;
          productObject.course_id = product.id;
          productObject.source = coursesOptions.value.find((course) => course.id === parseInt(product.id)).source;
        } else if (product.type === 'test') {
          if (!product.id) {
            notify({
              title: "Необходимо выбрать тип теста",
              type: "error"
            });

            return;
          }
          productObject.name = testOptions.value.find((test) => test.id === product.id).text;
          productObject.test_type = product.id;
        } else {
          productObject.name = types.find((type) => type.id === product.type).text;
        }

        subscriptionProducts.push(productObject);
      });

      if (subscriptionProducts.length < collectedProducts.length) {
        return;
      }

      const subscriptionObject = {
        title: subscriptionForm.title,
        duration_months: subscriptionForm.duration_months,
        price: subscriptionForm.price,
        available: !!subscriptionForm.available,
        products: JSON.stringify(subscriptionProducts),
      }

      if (route.params.id) {
        subscriptionObject.id = route.params.id;
        store.dispatch("subscription/updateSubscription", subscriptionObject).then(() => {
          router.push('/subscription/list');
        }).catch(standartError(setErrors, clearCustomErrors));
      } else {
        store.dispatch("subscription/setSubscription", subscriptionObject).then(() => {
          router.push('/subscription/list');
        }).catch(standartError(setErrors, clearCustomErrors))
      }

    }

    onBeforeMount(() => {
      isLoaded.value = false;
      store.dispatch("profile/getProfile").then(() => {
        if (!getRight('superadmin')) {
          router.push('/');
        }
      });

      store.dispatch("relations/getEntitiesList").then(() => {
        if (route.params.id) {
          products.splice(0);
          store.dispatch('subscription/getSubscription', route.params.id).then(() => {
            isLoaded.value = false;
            subscriptionForm.title = subscription.value.title;
            subscriptionForm.duration_months = subscription.value.duration_months;
            subscriptionForm.price = subscription.value.price;
            subscriptionForm.available = !!subscription.value.available;
            subscription.value.products.forEach((product) => {
              products.push({
                id: product.test_type ?? product.course_id,
                type: product.type,
                title: product.title,
                count: product.count,
                source: product.source ?? null,
              })
            });
            isLoaded.value = true;
          });
        } else {
          isLoaded.value = true;
        }
      });
    });

    return {
      route,
      isLoaded,
      subscriptionForm,
      types,
      products,
      testOptions,
      coursesOptions,
      removeField,
      addField,
      saveSubscription
    }
  }
}
</script>

<style scoped lang="scss">
  .available_checkbox,
  .remove_button {
    padding-left: 10px;
  }

  .subscription_form {
    display: flex;
    flex-direction: column;
    row-gap: 15px;
  }
  .control_button {
    margin-left: auto;
  }

  .append_button {
    margin-left: auto;
  }

  @media (max-width: 910px) {
    .available_checkbox,
    .remove_button {
      padding-left: 0;
    }
  }
</style>